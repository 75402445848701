import classNames from "classnames";

import classes from "./PageDocsGuides.module.pcss";
import {classPrefix, createStylesSelector, prepareGuidesRoutes, useOnMountUnsafe} from "~/lib";
import {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";
import {docsAPI} from "~/api/docsAPI";
import Loader from "~/components/common/Loader";
import {ReactNode} from "react";
import Router from "~/components/features/Router";
import {useSelector} from "react-redux";
import {getPageSlug} from "~/reducers/page";
import {useAppDispatch} from "~/store";
import {DocsOutputMenusListType2} from "~/@types/api/docsAPI";
import DocsGuidesWrapper from "~/components/templates/DocsGuidesWrapper";
import {injectDocsGuides as navigationInjectDocsGuides} from "~/reducers/navigation";
import ErrorMessage from "~/components/common/ErrorMessage";

export default function PageDocsGuides({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
}: PageDefaultProps) {
    const dispatch = useAppDispatch();
    const rootSlug = useSelector(getPageSlug);

    useOnMountUnsafe(
        () => {
            (async () => {
                const docsGuidesPromise = dispatch(docsAPI.endpoints.getDocsGuides.initiate());
                const result = await docsGuidesPromise;
                if (result.isSuccess) {
                    dispatch(navigationInjectDocsGuides({slugPrefix: rootSlug || "", ...result.data}));
                }
            })();
        },
        []
    );
    const result = useSelector(docsAPI.endpoints.getDocsGuides.select());
    const {
        data: guidesData,
        isLoading,
        error,
    } = result;

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);

    function renderGuides(guidesData: DocsOutputMenusListType2 | undefined): ReactNode {
        let ret: ReactNode;
        const prefixSlug = rootSlug || "docs-guides";
        if (guidesData && guidesData.guides) {
            ret = <Router
                pages={prepareGuidesRoutes(guidesData.guides)}
                renderFunction={(slug: string) => <DocsGuidesWrapper
                    styles={styles}
                    classes={classes}
                    rootSlug={rootSlug}
                    slug={slug}
                />}
                prefixSlug={prefixSlug}
            />;
        } else if (error) {
            ret = <ErrorMessage error={error} />;
        }

        return ret;
    }

    return <section className={classNames(classPrefix("page-docs-guides"), propsClassName, styles("page-docs-guides"))}>
        <h1>Guidelines</h1>
        {!isLoading
            ? <div className={"" + styles("guides-container")}>{renderGuides(guidesData)}</div>
            : <Loader size="large"/>
        }
    </section>;
}