import classNames from "classnames";

import classes from "./PageDocsChangelog.module.pcss";
import {classPrefix, createStylesSelector} from "~/lib";
import {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";
import {useGetDocsChangelogQuery} from "~/api/docsAPI";
import Loader from "~/components/common/Loader";
import Markdown from "~/components/common/Markdown";
import Span from "~/components/common/Span";
import DateTime from "~/components/common/Date";
import Block from "~/components/common/Block";

export default function PageDocsChangelog({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName
}: PageDefaultProps) {
    const {
        data: changelogData,
        isLoading,
        isFetching
    } = useGetDocsChangelogQuery({});

    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-docs-changelog"), propsClassName, styles("page-docs-changelog"))}>
        <h1>Last API Changes</h1>
        {!isLoading && !isFetching
            ? <div className={"" + styles("changelog-entries")}>
                {changelogData && changelogData.changelog && changelogData.changelog.length
                    ? changelogData.changelog.map(entry => {
                        const header = <>
                            <DateTime className={"" + styles("date")} options={{day: "numeric", month: "short", year: "numeric"}}>{entry.releaseDate}</DateTime>
                            {" "}
                            &ndash;
                            {" "}
                            <Span className={"" + styles("text")}>{entry.title}</Span>
                        </>;
                        return <article key={entry.version} className={"" + styles("entry")}>
                            <Block
                                styles={styles}
                                header={header}
                                meta={[
                                    {field: "date", title: "Release date", content: <DateTime>{entry.releaseDate}</DateTime>},
                                    {field: "version", title: "Version", content: entry.version},
                                ]}
                                contentClassName={"" + styles("description")}
                            >
                                <Markdown>{entry.description}</Markdown>
                            </Block>
                        </article>;
                    })
                    : "no data"}
            </div>
            : <Loader size="large"/>
        }
    </section>;
}