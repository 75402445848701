import classNames from "classnames";

import classes from "./PageDefault.module.css";
import {classPrefix, createStylesSelector} from "~/lib";
import {PageDefaultProps} from "~/@types/components/pages/PageDefaultProps";
import {useSelector} from "react-redux";
import {getPageSlug} from "~/reducers/page";

function PageDefault({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: PageDefaultProps) {
    const slug = useSelector(getPageSlug);
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-default"), propsClassName, styles("page-default"))}>
        page-default
        <pre>p:{JSON.stringify({props, slug}, null, " ")}</pre>
    </section>;
}

export default PageDefault;