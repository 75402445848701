import classNames from "classnames";

import classes from "./Page404.module.css";
import {classPrefix, createStylesSelector} from "~/lib";
import {Page404Props} from "~/@types/components/pages/Page404Props";

function Page404({
    classes: propsClasses,
    styles: propsStyles,
    className: propsClassName,
    ...props
}: Page404Props) {
    const styles = createStylesSelector([propsClasses, propsStyles, classes]);
    return <section className={classNames(classPrefix("page-404"), propsClassName, styles("page-404"))}>
        not found
        <pre>p:{JSON.stringify(props, null, " ")}</pre>
    </section>;
}

export default Page404;